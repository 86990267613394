export const brothers = [

    // Rho Class

    {
        img: require('../../../../assets/actives/brothers/Aaron_Lee.jpg'),
        name: "Aaron Lee",
        logo: require('../../../../assets/companyLogos/Oppti.png'),
        company: 'Oppti',
        width: "60%"
    },
    {
        img: require('../../../../assets/actives/brothers/Eddie_Rios.jpg'),
        name: "Eddie Rios",
        logo: require('../../../../assets/companyLogos/Ingram_Micro_Transparent.png'),
        company: 'Ingram Micro',
        width: "90%"
    },

    // Sigma Class

    {
        img: require('../../../../assets/actives/brothers/Julie_Hsieh.jpg'),
        name: "Julie Hsieh",
        logo: require('../../../../assets/companyLogos/lnData.png'),
        company: 'lnData',
        width: "40%"
    },
    {
        img: require('../../../../assets/actives/brothers/Justin_Chao.jpg'),
        name: "Justin Chao",
        logo: require('../../../../assets/companyLogos/CapitalOne.png'),
        company: 'Capital One',
        width: "75%"
    },

    // Tau Class

    {
        img: require('../../../../assets/actives/brothers/Alex_Zhou.jpg'),
        logo: require('../../../../assets/companyLogos/Riteline-removebg-preview.png'),
        company: 'Riteline USA',
        name: "Alex Zhou",
        width: "65%"
    },

    {
        img: require('../../../../assets/actives/brothers/Grace_Xu.jpg'),
        logo: require('../../../../assets/companyLogos/ScaleAgent_White.png'),
        company: 'ScaleAgent',
        name: "Grace Xu",
        width: "100%"
    },

    {
        img: require('../../../../assets/actives/brothers/Rainer_Setiawan.jpg'),
        logo: require('../../../../assets/companyLogos/Profound2.png'),
        company: 'Profound',
        name: "Rainer Setiawan",
        width: "90%"
    },
    {
        img: require('../../../../assets/actives/brothers/Tannvi_Banerjee.jpg'),
        name: "Tannvi Banerjee",
        logo: require('../../../../assets/companyLogos/Apsy_Transparent.png'),
        company: 'Apsy',
        width: "65%"
    },
    {
        img: require('../../../../assets/actives/brothers/Teresa_Chu.jpg'),
        name: "Teresa Chu",
        logo: require('../../../../assets/companyLogos/Citi.png'),
        company: 'CitiBank',
        width: "70%"
    },

    // Upsilon Class

    {
        img: require('../../../../assets/actives/brothers/Alie_Li.jpg'),
        logo: require('../../../../assets/companyLogos/Amazon_White.png'),
        company: 'Amazon',
        name: "Alie Li",
        width: "75%"
    },
    {
        img: require('../../../../assets/actives/brothers/Deborah_Kim.jpg'),
        logo: require('../../../../assets/companyLogos/Tarsus.jpeg'),
        company: 'Tarsus Pharmaceuticals, Inc.',
        name: "Deborah Kim",
        width: "55%"
    },
    {
        img: require('../../../../assets/actives/brothers/Rayyaan_Majid.jpg'),
        company: '',
        name: "Rayyaan Majid",
        width: "75%"
    },
    {
        img: require('../../../../assets/actives/brothers/Joseph_Tom.jpg'),
        logo: require('../../../../assets/companyLogos/CapitalOne.png'),
        company: 'Capital One',
        name: "Joseph Tom",
        width: "75%"
    },
    {
        img: require('../../../../assets/actives/brothers/Zoe_Lui.jpg'),
        name: "Zoe Lui",
        company: "JT Aesthetics & Co",
        width: "75%"
    },

    // Chi Class

    {
        img: require('../../../../assets/actives/brothers/Ethan_Tung.jpg'),
        name: "Ethan Tung",
        logo: require('../../../../assets/companyLogos/Goldman_Sachs.png'),
        company: 'Goldman Sachs',
        width: "75%"
    },
    {
        img: require('../../../../assets/actives/brothers/Jessica_Nguyen.jpg'),
        name: "Jessica Nguyen",
        logo: require('../../../../assets/companyLogos/EY.png'),
        company: 'EY',
        width: "55%"
    },
    {
        img: require('../../../../assets/actives/brothers/Kate_Arnold.jpg'),
        name: "Kate Arnold",
        logo: require('../../../../assets/companyLogos/starbucks-logo-png-transparent.png'),
        company: 'Starbucks',
        width: "60%"
    },
    {
        img: require('../../../../assets/actives/brothers/Thomas_Lin.jpg'),
        name: "Thomas Lin",
        logo: require('../../../../assets/companyLogos/lifetime-removebg-preview.png'),
        company: 'Lifetime Activities',
        width: "65%"
    },

    // Psi Class

    {
        img: require('../../../../assets/actives/brothers/Tristan_Dizon.jpg'),
        name: "Tristan Dizon",
        logo: require('../../../../assets/companyLogos/EY.png'),
        company: 'EY',
        width: "55%"
    },

];

export default { brothers };